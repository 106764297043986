import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import "./styles.scss"

const FeaturedCarousel = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(
        frontmatter: { sectionKey: { eq: "sections/featured-carousel" } }
      ) {
        frontmatter {
          carouselImages {
            img {
              publicURL
            }
            id
            alt
          }
        }
      }
    }
  `)

  const { carouselImages } = data.markdownRemark.frontmatter

  return (
    <div className="container section-gap">
      <h3 className="font-semibold text-center mb-5">MKI Legal has been featured in</h3>
      <div className="row flex-wrap align-items-center justify-content-center">
        {carouselImages.map(({ img, alt, id }, i) => {
          const marginBottom = i === carouselImages.length - 1 ? "" : "mb-5 mb-lg-0"

          return (
            <div
              className={`d-flex justify-content-center col-6 px-4 px-sm-5 px-md-4 px-lg-2 col-md-4 custom-col-lg-7 ${marginBottom}`}
              key={`carouselImages--${i}`}
            >
              <img id={id} src={img.publicURL} alt={alt} className="media-section-image"/>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default FeaturedCarousel
